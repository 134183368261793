@import "./variables";

.container {
    @include container;
}

.grid {
    display: grid;
    --grid-column-count: 12;
    grid-template-columns: repeat(var(--grid-column-count), minmax(0, 1fr));
    --grid-gap: 20px;
    gap: var(--grid-gap);
    /*
  container width = (gap X # of gaps (aka # of columns - 1)) + (column width X # of columns)
  Below solves for column width - useful for calculations, generally best to use other layout methods
  */
    --grid-column-width: calc(
        (
                var(--current-width) -
                    ((var(--grid-column-count) - 1) * var(--grid-gap))
            ) /
            var(--grid-column-count)
    );

    @media screen and ($below-tablet) {
        --grid-gap: 15px;
    }

    @media screen and ($below-mobile) {
        --grid-column-count: 4;
    }
}

.gridContainer {
    composes: container;
    composes: grid;
}

.fullWidthContainer {
    --container-min-margin: 0;

    > * {
        grid-column: span 12;
    }

    @media ($below-mobile) {
        > * {
            grid-column: span 4;
        }
    }
}

.paddedContainer {
    // Useful for calculations elsewhere, should generally prefer other layout methods
    --padded-container-width: calc(
        (var(--grid-column-width) * 8) + (var(--grid-gap) * 7)
    );

    > * {
        grid-column: 3 / span 8;
    }

    @media ($below-tablet) {
        --padded-container-width: var(--current-width);

        > * {
            grid-column: span 12;
        }
    }

    @media ($below-mobile) {
        > * {
            grid-column: span 4;
        }
    }
}

// In past projects we've capped max-width at something high.
// This provides a low-friction way to make it easier to keep that option open
.maxSiteWidth {
    width: 100%;
}
